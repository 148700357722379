import AutoComplete from './Autocomplete';
import ScpItem from './ScpItem';
import ScpDetail from './ScpDetails';

function ResultsPane(props) {
  const { activeMarker, activeMarkerDetails, currentLocation, localMarkers, onActiveMarkerChange, onCurrentLocationChange, onActiveRouteChange } = props
  return (
    <>
      <div className="absolute top-0 left-0 z-20 w-96 p-4">
        <AutoComplete onCurrentLocationChange={onCurrentLocationChange} />
      </div>
      <div className={(activeMarker ? 'h-full ' : '') + 'absolute top-0 left-0 z-10 w-96 overflow-hidden'}>
        <div className="w-96 bg-gm-dark" style={{ 'height': '1px' }}>&nbsp;</div>
        <div className={!activeMarker ? 'hidden' : 'bg-white h-full overflow-y-scroll'}>
          {activeMarkerDetails && (
            <ScpDetail
              key={`${activeMarkerDetails.label}-${activeMarkerDetails.id}`}
              name={activeMarkerDetails.name}
              address={activeMarkerDetails.address}
              id={activeMarkerDetails.id}
              label={activeMarkerDetails.label}
              coords={activeMarkerDetails.coords}
              business={activeMarkerDetails?.response?.result}
              currentLocation={currentLocation}
              onActiveMarkerChange={onActiveMarkerChange}
              onActiveRouteChange={onActiveRouteChange}
            />
          )}
        </div>
        {!activeMarkerDetails && localMarkers &&
          <div className={!localMarkers ? 'hidden' : 'bg-white pt-20 w-96 h-full overflow-scroll'}>
            {localMarkers.data.map((scp) => {
              return (
                <ScpItem
                  key={`${scp.label}-${scp.id}`}
                  name={scp.name}
                  address={scp.address}
                  id={scp.id}
                  label={scp.label}
                  coords={new window.google.maps.LatLng(scp.latitude, scp.longitude)}
                  business={scp.response === '' ? scp.response : scp.response.result}
                  onActiveMarkerChange={onActiveMarkerChange}
                />
              );
            })}
          </div>
        }
      </div>
    </>
  );
}

export default ResultsPane;
