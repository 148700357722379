import { useCallback, useState, useEffect, memo, useRef } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import dotrb from '../../images/dot-rb.png';
import dotdb from '../../images/dot-db.png';
import markerdb from '../../images/marker-db.png';
import markerrb from '../../images/marker-rb.png';
import locationDot from '../../images/geolocated_marker.png';

function GMaps(props) {
  const {
    currentLocation,
    onActiveMarkerChange,
    localMarkers,
    activeMarkerRef,
    mapRef,
    activeRoute,
    network
  } = props;

  const currentDirection = useRef(false);

  const [map, setMap] = useState(null);
  const [markerSize, setMarkerSize] = useState(false);
  const [markerList, setMarkerList] = useState(false);

  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  const defaultCenter = {
    lat: 47.5,
    lng: 2,
  };

  const defaultZoom = 6;

  const icons = {
    dotrb: {
      url: dotrb,
      scaledSize: new window.google.maps.Size(12, 12),
    },
    dotdb: {
      url: dotdb,
      scaledSize: new window.google.maps.Size(12, 12),
    },
    markerdb: {
      url: markerdb,
      scaledSize: new window.google.maps.Size(33, 50),
    },
    markerrb: {
      url: markerrb,
      scaledSize: new window.google.maps.Size(33, 50),
    },
    location: {
      url: locationDot,
      scaledSize: new window.google.maps.Size(32, 32),
    },
    markeractivedb: {
      url: markerdb,
      scaledSize: new window.google.maps.Size(57, 90),
    },
    markeractiverb: {
      url: markerrb,
      scaledSize: new window.google.maps.Size(57, 90),
    },
  };

  useEffect(() => {
    if (map && localMarkers.bounds) {
      map.fitBounds(localMarkers.bounds, {
        left: 24 * parseFloat(getComputedStyle(document.documentElement).fontSize),
      });
    } else if (map) {
      map.setZoom(defaultZoom);
      map.setCenter(defaultCenter);
    }
  }, [localMarkers]);

  useEffect(() => {
    if (currentDirection.current) {
      currentDirection.current.setMap(null);
    }

    if (!activeRoute) {
      return;
    }

    const directionsService = new window.google.maps.DirectionsService();
    const directionsRenderer = new window.google.maps.DirectionsRenderer({
      preserveViewport: true,
      suppressMarkers: true,
    });

    directionsRenderer.setMap(map);
    currentDirection.current = directionsRenderer;

    const params = {
      origin: currentLocation,
      destination: activeMarkerRef.current.marker.getPosition(),
      travelMode: window.google.maps.TravelMode.DRIVING,
    };

    directionsService.route(params, (result, status) => {
      if (status === 'OK') {
        directionsRenderer.setDirections(result);
        if (result?.routes[0]) {
          map.fitBounds(result.routes[0].bounds, {
            left: 24 * parseFloat(getComputedStyle(document.documentElement).fontSize),
          });
        }
      }
    });
  }, [activeRoute]);

  const loadMarkers = async () => {
    const url = `${process.env.API_URL}cors.php?network=${network}`;
    fetch(url)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          return response.json();
        }
        return false;
      })
      .then((response) => {
        console.log(response);
        setMarkerList(response);
      });
  };

  const onLoad = useCallback(function callback(map) {
    setMap(map);
    map.setZoom(defaultZoom);
    map.setCenter(defaultCenter);
    mapRef.current = map;
    loadMarkers();
  }, []);

  const onZoomChanged = () => {
    if (map && !currentLocation && map.getZoom() > 14) {
      setMarkerSize(true);
    } else {
      setMarkerSize(false);
    }
  };

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onClickMarker = (scp) => {
    onActiveMarkerChange({
      label: scp.label,
      id: scp.id,
      coords: new window.google.maps.LatLng(scp.latitude, scp.longitude),
    });
  };

  const onClickMap = () => {
    onActiveMarkerChange(false);
  };

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onZoomChanged={onZoomChanged}
      options={{
        mapTypeControl: false,
        clickableIcons: false,
        fullscreenControl: false,
        streetViewControl: false,
      }}
      onClick={onClickMap}
    >
      <Marker
        key={`active${Math.random()}`}
        ref={activeMarkerRef}
        icon={icons.markeractivedb}
        position={activeMarkerRef?.current?.marker?.getPosition() || defaultCenter}
        options={{ zIndex: 100000, optimized: false }}
        visible={activeMarkerRef.current.id ? true : false}
      />
      {markerList &&
        markerList.data.map((scp) => {
          return (
            <Marker
              key={scp.label + scp.id + Math.random()}
              position={{ lat: parseFloat(scp.latitude), lng: parseFloat(scp.longitude) }}
              icon={
                // eslint-disable-next-line no-nested-ternary
                !markerSize
                  ? scp.label === 'rb'
                    ? icons.dotrb
                    : icons.dotdb
                  : scp.label === 'rb'
                    ? icons.markerrb
                    : icons.markerdb
              }
              onClick={() => onClickMarker(scp)}
              visible={!currentLocation}
            />
          );
        })}
      {currentLocation && (
        <Marker
          key="position"
          icon={icons.location}
          position={currentLocation}
          animation={google.maps.Animation.DROP}
          options={{ optimized: false, zIndex: -1000 }}
        />
      )}
      {localMarkers &&
        localMarkers.data.map((scp) => {
          return (
            <Marker
              key={scp.label + scp.id + Math.random()}
              position={new window.google.maps.LatLng(scp.latitude, scp.longitude)}
              icon={scp.label === 'rb' ? icons.markerrb : icons.markerdb}
              onClick={() => onClickMarker(scp)}
              options={{ optimized: false, zIndex: -1000 }}
            />
          );
        })}
      )
    </GoogleMap>
  );
}

const checkProps = (p, n) => {
  if (
    p?.activeRoute === n?.activeRoute &&
    p?.currentLocation === n?.currentLocation &&
    p?.localMarkers === n?.localMarkers
  ) {
    return true;
  }
  return false;
};

export default memo(GMaps, checkProps);
