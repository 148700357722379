import { useEffect, useState } from 'react';
import RatingStars from './RatingStars';
import userContent from '../../images/user-content.png';

export default function ScpItem(props) {
  const [cleanedApiData, setCleanedApiData] = useState({});

  const { name, address, label, business, coords, id, onActiveMarkerChange } = props;

  useEffect(() => {
    const now = new Date();
    const dayOfWeekDigit = now.getDay();
    const dayOfWeekDigitFR = (now.getDay() + 6) % 7;
    const hourmin = now.getHours() * 100 + now.getMinutes();
    const data = {};
    data.labelName = label === 'rb' ? 'Rebadge™' : 'Duplibadge™';
    data.name = business?.name ? business?.name : name;
    data.phone = business?.formatted_phone_number ? business?.formatted_phone_number : false;
    data.address = business?.formatted_address ? business?.formatted_address : address;
    data.hasPeriods = business?.opening_hours?.periods;

    data.opennow = (() => {
      let isOpen = false;

      if (business?.opening_hours?.periods) {
        // Detect 24/7
        if (
          business?.opening_hours.periods[0].open?.day === 0 &&
          business?.opening_hours.periods[0].open?.time === '0000' &&
          business?.opening_hours.periods[0].close == undefined
        ) {
          return true;
        }

        business?.opening_hours?.periods.forEach((element) => {
          if (element.close?.day === dayOfWeekDigit || element.open?.day === dayOfWeekDigit) {
            if (
              (hourmin < parseInt(element.close.time) || parseInt(element.close.time) === 0) &&
              hourmin > parseInt(element.open.time)
            ) {
              isOpen = true;
              return true;
            }
          }
          return false;
        });
      }

      return isOpen;
    })();

    data.opentoday = business?.opening_hours?.weekday_text
      ? business?.opening_hours?.weekday_text[dayOfWeekDigitFR]
      : '';
    data.rating = business?.rating ? business?.rating : false;
    data.user_ratings_total = business?.user_ratings_total ? business?.user_ratings_total : false;
    data.best_review = (() => {
      window.bestReviewRating = 0;
      window.bestReview = false;
      if (business?.reviews) {
        business?.reviews.forEach((element) => {
          if (element.rating > window.bestReviewRating) {
            window.bestReview = (element.text.length > 100) ? element.text.substring(0, 100) + '...' : element.text;
            window.bestReviewRating = element.rating;
          }
        });
      }
      return window.bestReview;
    })();

    setCleanedApiData(data);
  }, []);
  const onClickResult = () => {
    onActiveMarkerChange({ label, id, coords });
  };
  return (
    <div onClick={onClickResult} className="scp-item p-4 bg-white border-b-gm-border-color-light border-b hover:bg-gm-item-hover cursor-pointer">
      <div className="font-medium text-gm-dark text-base pb-1 ">
        {cleanedApiData.labelName} - {cleanedApiData.name}
      </div>
      {cleanedApiData.rating && (
        <div className="pb-1">
          <span className="font-normal	text-gm-light text-sm	mr-1">{cleanedApiData.rating.toLocaleString('fr-FR', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</span>
          <RatingStars rating={cleanedApiData.rating} />
          {cleanedApiData.user_ratings_total && (
            <span className="font-normal	text-gm-light text-sm	ml-1">
              ({cleanedApiData.user_ratings_total})
            </span>
          )}
        </div>
      )}
      <div className="text-gm-light text-sm">{cleanedApiData.address}</div>
      {cleanedApiData.hasPeriods && (
        <div>
          <span
            className={cleanedApiData.opennow ? 'text-gm-business-open text-sm' : 'text-gm-business-close text-sm'}
          >
            {cleanedApiData.opennow ? 'Ouvert' : 'Fermé'}
          </span>
          <span className="text-gm-light text-sm">  ⋅ {cleanedApiData.opentoday}{cleanedApiData.phone ? " ⋅ " + cleanedApiData.phone : ""}</span>
        </div>
      )}
      {cleanedApiData.best_review && (
        <div className="font-normal text-gm-light text-sm mt-2"><img src={userContent} className='w-4 h-4 inline-block mr-2 align-text-bottom'></img>"{cleanedApiData.best_review}"</div>
      )}
    </div >
  );
}
