import { useState, useEffect, useRef } from 'react';
import httpBuildQuery from 'http-build-query';
import { useJsApiLoader } from '@react-google-maps/api';

import ResultsPane from './ResultsPane';
import GMaps from './GMaps';

import markerdb from '../../images/marker-db.png';
import markerrb from '../../images/marker-rb.png';
import loadingIconBig from '../../images/loader-200.svg';



const librariesList = ['places'];
const apiKey = process.env.GMAPS_API_KEY;

function App() {
  const activeMarkerRef = useRef(false);
  const mapRef = useRef();

  const network = (new URLSearchParams(window.location.search).get('network')) ?? 'all';

  const [activeRoute, setActiveRoute] = useState(false);
  const [activeMarker, setActiveMarker] = useState(false);
  const [activeMarkerDetails, setActiveMarkerDetails] = useState(false);
  const [localMarkers, setLocalMarkers] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(false);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: apiKey,
    libraries: librariesList,
  });

  function handleCurrentLocationChange(newCurrentLocation) {
    setCurrentLocation(newCurrentLocation);
    setActiveRoute(false);
  }

  function handleActiveMarkerChange(newActiveMarker) {
    setActiveMarker(newActiveMarker);
    setActiveRoute(false);
    setActiveMarkerDetails(false);
  }

  function handleActiveRouteChange(newRoute) {
    setActiveRoute(newRoute);
  }

  async function loadActiveMarker() {
    if (!activeMarker) {
      return;
    }

    const params = {
      id: activeMarker.id,
      label: activeMarker.label,
    };

    const url = `${process.env.API_URL}placeId.php?${httpBuildQuery(params)}`;
    const response = await fetch(url);
    const data = await response.json();
    setActiveMarkerDetails(data);
  }

  useEffect(() => {
    if (currentLocation) {
      const url = `${process.env.API_URL}search.php?${httpBuildQuery(currentLocation)}&network=${network}`;
      fetch(url)
        .then((response) => {
          if (response.status >= 200 && response.status <= 299) {
            return response.json();
          }
          return false;
        })
        .then((response) => {
          setLocalMarkers(response);
          setActiveMarker(false);
          setActiveMarkerDetails(false);
        })
        .catch(() => {
          setLocalMarkers(false);
        });
    } else {
      setActiveMarker(false);
      setLocalMarkers(false);
    }
  }, [currentLocation]);

  useEffect(() => {
    if (activeMarker) {
      if (window?.google?.maps && activeMarkerRef.current) {
        const icons = {
          markeractivedb: {
            url: markerdb,
            scaledSize: new window.google.maps.Size(57, 90),
          },
          markeractiverb: {
            url: markerrb,
            scaledSize: new window.google.maps.Size(57, 90),
          },
        };

        loadActiveMarker();

        activeMarkerRef.current.marker.setVisible(false);
        activeMarkerRef.current.marker.setPosition(activeMarker.coords);
        activeMarkerRef.current.marker.setIcon(
          activeMarker.label === 'rb' ? icons.markeractiverb : icons.markeractivedb,
        );

        activeMarkerRef.current.marker.setVisible(true);
        if (mapRef.current && !mapRef.current.getBounds().contains(activeMarker.coords)) {
          mapRef.current.panTo(activeMarker.coords);
        }
      }
    } else if (activeMarkerRef.current) {
      activeMarkerRef.current.marker.setVisible(false);
    }
  }, [activeMarker]);

  return isLoaded ? (
    <div className="mx-auto h-screen overflow-hidden">
      <GMaps
        network={network}
        currentLocation={currentLocation}
        localMarkers={localMarkers}
        activeRoute={activeRoute}
        activeMarkerRef={activeMarkerRef}
        mapRef={mapRef}
        onActiveMarkerChange={handleActiveMarkerChange}
      />
      <ResultsPane
        activeMarker={activeMarker}
        activeMarkerDetails={activeMarkerDetails}
        localMarkers={localMarkers}
        currentLocation={currentLocation}
        onActiveMarkerChange={handleActiveMarkerChange}
        onCurrentLocationChange={handleCurrentLocationChange}
        onActiveRouteChange={handleActiveRouteChange}
      />
    </div>
  ) : (
    <div className="loading w-full h-full flex justify-center items-center">
      <img src={loadingIconBig} alt='icon big' />
    </div>
  );
}

export default App;
