import { useRef, useEffect, useState } from 'react';
import searchIcon from '../../images/search.svg';
import locatemeIcon from '../../images/locateme.svg';
import searchClose from '../../images/search-close.svg';
import loadingIcon from '../../images/loader-24.svg';

export default function AutoComplete(props) {
  const isInitialMount = useRef(true);
  const autocompleteRef = useRef();
  const [query, setQuery] = useState('');
  const [locating, setLocating] = useState(false);

  const { onCurrentLocationChange } = props;

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      const input = document.getElementById('store-locator-search-input');
      const options = {
        componentRestrictions: { country: ['fr'] },
        types: ['geocode'],
      };
      const autocomplete = new google.maps.places.Autocomplete(input, options);

      google.maps.event.addListener(autocomplete, 'place_changed', () => {

        if (autocomplete.getPlace().geometry) {
          const center = {
            lat: autocomplete.getPlace().geometry.location.lat(),
            lng: autocomplete.getPlace().geometry.location.lng(),
          };
          onCurrentLocationChange(center);
        }
      });
    }
  }, []);

  const locateme = () => {
    if (navigator.geolocation) {
      setLocating(true);
      navigator.geolocation.getCurrentPosition((position) => {
        setLocating(false);
        autocompleteRef.current.value = 'Votre Position';
        setQuery('Votre Position');
        onCurrentLocationChange({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }

  };

  const handleSearch = (event) => {
    setQuery(event.target.value);
    if (event.target.value === '') {
      onCurrentLocationChange(false);
    }
  };

  const clearCurrentPosition = () => {
    onCurrentLocationChange(false);
    autocompleteRef.current.value = '';
    setQuery('');
  };

  return (
    <div>
      <div className="rounded-lg border-0 w-full p-3 shadow-md flex items-center bg-white justify-between">
        <img className="mr-2" src={searchIcon} alt="search icon" />
        <input
          ref={autocompleteRef}
          onChange={handleSearch}
          type="text"
          id="store-locator-search-input"
          className="w-60 border-0 outline-none"
          placeholder="Entrez votre adresse..."
        />
        <img
          className={query === '' ? 'opacity-0' : 'cursor-pointer hover:scale-110'}
          src={searchClose}
          onClick={clearCurrentPosition}
          alt="clear current position"
        />
        {!locating ? (<img className="ml-2 cursor-pointer opacity-70 hover:scale-110" src={locatemeIcon} onClick={locateme} alt="locate me" />)
          : (<img className="ml-2" src={loadingIcon} alt="locate me" />)}
      </div>
    </div >
  );
}
