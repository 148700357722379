export default function RatingStars(props) {
  const starClass = (rank, value) => {
    if (rank === 1) {
      return 'full-star';
    }
    if (value >= rank - 0.3) {
      return 'full-star';
    }
    if (value > rank - 1) {
      return 'half-star';
    }

    return 'empty-star';
  };

  return (
    <>
      <span className={starClass(1, props.rating)} />
      <span className={starClass(2, props.rating)} />
      <span className={starClass(3, props.rating)} />
      <span className={starClass(4, props.rating)} />
      <span className={starClass(5, props.rating)} />
    </>
  );
}
