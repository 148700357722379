import '../scss/app.scss';
import ReactDOM from 'react-dom/client';
import App from './components/App';

function Root() {
  return <App />;
}

const container = document.getElementById('store-locator');
const root = ReactDOM.createRoot(container);

root.render(<Root />);
