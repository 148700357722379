import { useState, useEffect } from 'react';
import RatingStars from './RatingStars';

import httpBuildQuery from 'http-build-query';

import backIcon from '../../images/back.svg';
import routeIcon from '../../images/route.svg';
import phoneIcon from '../../images/phone.png';
import websiteIcon from '../../images/website.png';
import addressIcon from '../../images/address.png';
import verifiedIcon from '../../images/verified.png';
import periodIcon from '../../images/period.png';
import userContent from '../../images/user-content.png';


const apiKey = process.env.GMAPS_API_KEY;

function ScpDetails(props) {
  const { name, address, label, business, coords, currentLocation, id, onActiveMarkerChange, onActiveRouteChange } = props;
  const [cleanedApiData, setCleanedApiData] = useState({});

  useEffect(() => {
    const now = new Date();
    const dayOfWeekDigit = now.getDay();
    const dayOfWeekDigitFR = (now.getDay() + 6) % 7;
    const hourmin = now.getHours() * 100 + now.getMinutes();
    const data = {};
    data.labelName = label === 'rb' ? 'Rebadge™' : 'Duplibadge™';
    data.name = business?.name ? business?.name : name;
    data.phone = business?.formatted_phone_number ? business?.formatted_phone_number : false;
    data.address = business?.formatted_address ? business?.formatted_address : address;
    data.hasPeriods = business?.opening_hours?.periods;
    data.weekday_text = business?.opening_hours?.weekday_text ? business?.opening_hours?.weekday_text : false;
    data.photo = business?.photos ? (() => {
      const baseUrl = 'https://maps.googleapis.com/maps/api/place/photo?';
      const params = {
        maxwidth: 400, //business?.photos[0].width,
        photo_reference: business?.photos[0].photo_reference,
        key: apiKey,
      }

      return baseUrl + httpBuildQuery(params);

    }
    )() : false;

    data.opennow = (() => {
      let isOpen = false;

      if (business?.opening_hours?.periods) {
        // Detect 24/7
        if (
          business?.opening_hours.periods[0].open?.day === 0 &&
          business?.opening_hours.periods[0].open?.time === '0000' &&
          business?.opening_hours.periods[0].close == undefined
        ) {
          return true;
        }

        business?.opening_hours?.periods.forEach((element) => {
          if (element.close?.day === dayOfWeekDigit || element.open?.day === dayOfWeekDigit) {
            if (
              (hourmin < parseInt(element.close.time) || parseInt(element.close.time) === 0) &&
              hourmin > parseInt(element.open.time)
            ) {
              isOpen = true;
              return true;
            }
          }
          return false;
        });
      }

      return isOpen;
    })();

    data.opentoday = business?.opening_hours?.weekday_text
      ? business?.opening_hours?.weekday_text[dayOfWeekDigitFR]
      : '';
    data.rating = business?.rating ? business?.rating : false;
    data.user_ratings_total = business?.user_ratings_total ? business?.user_ratings_total : false;
    data.reviews = business?.reviews ? business?.reviews : false;
    data.website = business?.website ? business?.website : false;

    setCleanedApiData(data);
  }, [label, id]);

  const disableActiveMarler = () => {
    onActiveMarkerChange(false);
  };

  const loadRoute = () => {
    onActiveRouteChange(true);
  };

  return (
    <>
      {cleanedApiData.photo ? (
        <div className='h-60 bg-gm-light bg-opacity-50'>
          <img
            className="object-cover w-full h-full"
            src={cleanedApiData.photo}
            alt={cleanedApiData.labelName}
          />
        </div>
      ) : (
        <div className="pt-20" />
      )}
      <div className="border-b-gm-border-color-light border-b uppercase h-7 p-6 flex justify-between items-center">
        <img
          className="mr-2 cursor-pointer hover:scale-110"
          src={backIcon}
          alt="retour"
          onClick={disableActiveMarler}
        />
        <div>Boutique {cleanedApiData.labelName}</div>
        <img
          className={"ml-2 cursor-pointer hover:scale-110" + (!currentLocation ? ' invisible' : '')}
          src={routeIcon}
          onClick={loadRoute}
          alt="itinéraire"
        />
      </div>
      <div className="scp-item">
        <div className="border-b-gm-border-color-light border-b p-6">
          <div className="font-medium text-gm-dark text-base pb-1 ">{cleanedApiData.name}</div>
          {cleanedApiData.address && !cleanedApiData.rating && (
            <div className="text-gm-dark text-sm mb-3 flex items-center">
              <img className="w-6 mr-3 " src={addressIcon} alt="adresse" />
              <div className="">{cleanedApiData.address}</div>
            </div>
          )}
          {cleanedApiData.rating && (
            <div className="mb-2">
              <span className="font-normal	text-gm-light text-sm	mr-1">{cleanedApiData.rating.toLocaleString('fr-FR', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</span>
              <RatingStars rating={cleanedApiData.rating} />
              {cleanedApiData.user_ratings_total && (
                <span className="font-normal	text-gm-blue text-sm	ml-1">
                  {cleanedApiData.user_ratings_total} avis
                </span>
              )}
            </div>
          )}
        </div>
        <div className="p-6 border-b-gm-border-color-light border-b">
          {cleanedApiData.address && !cleanedApiData.rating && (
            <div className="text-gm-dark text-sm flex items-center">
              <img className="w-6 mr-3 " src={verifiedIcon} alt="adresse" />
              <div className="text-gm-blue"><a target="_new" href="mailto:contact@rebadge.co" > Revendiquer cet établissement</a></div>
            </div>
          )}
          {cleanedApiData.address && cleanedApiData.rating && (
            <div className="text-gm-dark text-sm mb-3 flex items-center">
              <img className="w-6 mr-3 " src={addressIcon} alt="adresse" />
              <div className="">{cleanedApiData.address}</div>
            </div>
          )}
          {cleanedApiData.phone && (
            <div className="text-gm-dark text-sm mb-3 flex items-center">
              <img className="w-6 mr-3 " src={phoneIcon} alt="téléphone" />
              <div className=" text-gm-blue"><a target="_new" href={"tel:" + cleanedApiData.phone}>{cleanedApiData.phone}</a></div>
            </div>
          )}
          {cleanedApiData.website && (
            <div className="text-gm-dark text-sm mb-3 flex items-center">
              <img className="w-6 mr-3 " src={websiteIcon} alt="téléphone" />
              <div className=" text-gm-blue"><a target="_new" href={cleanedApiData.website}>{(new URL(cleanedApiData.website)).hostname}</a></div>
            </div>
          )}
          {cleanedApiData.hasPeriods && (
            <div className="text-gm-dark text-xs font-medium mb-3 flex items-start">
              <img className="w-6 mr-3 " src={periodIcon} alt="horaires" />
              <div
                className={cleanedApiData.opennow ? 'text-gm-business-open text-sm' : 'text-gm-business-close text-sm'}
              >
                <div className='font-semibold'>{cleanedApiData.opennow ? 'Ouvert' : 'Fermé'}</div>
                {cleanedApiData.weekday_text &&
                  cleanedApiData.weekday_text.map((weekday) => {
                    return <div className="text-gm-light">{weekday}</div>;
                  })}
              </div>

            </div>
          )}
        </div>
        {cleanedApiData.reviews && (
          <div className="p-6 border-b-gm-border-color-light border-b">
            <h2 className="text-gm-dark font-medium text-base mb-4">Avis</h2>
            {cleanedApiData.reviews.map((review) => {
              return (
                <div className="font-normal text-gm-light text-sm mt-2 border-b-gm-border-color-light border-b py-4">
                  <div className='flex items-center text-gm-item mb-2'>
                    <img src={userContent} className='w-6 h-6 rounded-full mr-3' />
                    {review.author_name}
                  </div>
                  <div className="flex items-center mb-2">
                    <RatingStars rating={review.rating} /> <div className="	text-gm-light text-sm	ml-2">{review.relative_time_description}</div>
                  </div>
                  <div>{review.text}</div>
                </div>
              );
            })}
          </div>
        )}

      </div>
    </>
  );
}

export default ScpDetails;
